import styled from "styled-components";
import { AppInputRadioWrap } from "../input-radio/style";

const AppMeasSysSelector = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 0 1.5rem;
  padding: .375rem;
  width: 100%;
  background-color: var(--white-8);
  border-radius: 1rem;
  font-size: .75rem;
  font-weight: 100;
  text-transform: uppercase;

  ${AppInputRadioWrap} {
    width: 50%;
  }

  input {
    position: absolute;
    top: -10rem;
    left: -10rem;

    &:checked {
      &+label {
        background-color: var(--white);
        color: var(--blue);
        font-weight: 600;
      }
    }
  }

  label {
    display: flex;
    justify-content: center;
    cursor: pointer;
    padding: .125rem;
    border-radius: 1rem;
    transition: font-weight .3s linear;

    &:hover {
      font-weight: 600;
    }
  }
`;

export const AppMeasSysSelectorUnit = styled.span`

`;

export default AppMeasSysSelector;
