import { ChangeEvent, useState } from "react";
import { InputField, LanguageSwitcher, MeasSysSelector, Result } from "..";
import AppCalculator, { AppCalculatorBox } from "./style";
import type { calculatorPropsType } from "../../data-structures";
import { useTranslation } from 'react-i18next';

const Calculator = () => {
  const { t } = useTranslation();
  const defaultData = {
    height: 0,
    measSys: "metric",
    weight: 0,
  };

  const [data, setData] = useState<calculatorPropsType>(defaultData);

  const handleInputFieldChange = (e: ChangeEvent<HTMLInputElement>): void => {
    const { name, value } = e.target;
    setData(prevData => ({ ...prevData, [name]: value, }));
  };

  const handleRadioInputChange = (e: ChangeEvent<HTMLInputElement>): void => {
    const { id } = e.target;
    setData(prevData => ({ ...defaultData, measSys: id, }));
  };

  return (
    <AppCalculator>
      <AppCalculatorBox>
        <Result
          height={data.height}
          measSys={data.measSys}
          weight={data.weight}
        />
        <MeasSysSelector
          onChange={handleRadioInputChange}
        />
        <InputField
          id="height"
          label={t("height")}
          name="height"
          onChange={handleInputFieldChange}
          unit={data.measSys === "metric" ? "cm" : "inch"}
          value={data?.height}
        />
        <InputField
          id="weight"
          label={t("weight")}
          name="weight"
          onChange={handleInputFieldChange}
          unit={data.measSys === "metric" ? "kg" : "lbs"}
          value={data?.weight}
        />
        <LanguageSwitcher />
      </AppCalculatorBox>
    </AppCalculator>
  )
};

export default Calculator;
