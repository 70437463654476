import styled from "styled-components";

const AppLngSwitcher = styled.ul`
  display: flex;
  margin-top: .5rem;
  font-size: .75rem;
`;

export const AppLngSwitcherItemWrap = styled.li`
  &:nth-child(1) {
    &::after {
      content: "|";
      margin-inline: .25rem;
      color: var(--blue-dark);
    }
  }
`;

export const AppLngSwitcherItem = styled.span<{ $active: boolean; }>`
  cursor: ${props => props.$active ? "default" : "pointer"};;
  color: ${props => props.$active ? "inherit" : "var(--blue-dark)"};
  text-transform: uppercase;
`;

export default AppLngSwitcher;
