import AppInputRadio, { AppInputRadioWrap } from "./style";
import { inputRadioPropsType } from "../../data-structures";

const InputRadio = ({checked, id, label, name, onChange}: inputRadioPropsType) => {
  return (
    <AppInputRadioWrap>
      <AppInputRadio
        defaultChecked={checked}
        id={id}
        name={name}
        onChange={onChange}
        type="radio"
      />
      <label htmlFor={id}>
        {label}
      </label>
    </AppInputRadioWrap>
  );
};

export default InputRadio;
