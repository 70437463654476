import styled from "styled-components";

const getColor = (index: number): string => {
  switch (index) {
    case 1:
      return "var(--yellow)";
    case 2:
      return "var(--green)";
    case 3:
      return "var(--yellow)";
    case 4:
      return "var(--orange)";
    case 5:
      return "var(--red)";
    case 6:
      return "var(--red)";
    default:
      return "transparent";
  }
};

const AppResult = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin-bottom: 2rem;
  width: 12rem;
  aspect-ratio: 2/1;
  background-color: var(--white-8);
  border-radius: 0 0 6rem 6rem;
  color: var(--white);
  font-weight: 600;
`;

export const AppResultText = styled.div`
  margin-bottom: .5rem;
  font-size: 1.125rem;
  text-transform: uppercase;
`;

export const AppResultNumber = styled.div<{ $statusIndex: number; }>`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 2.5rem;
  aspect-ratio: 1/1;
  background-color: ${props => getColor(props.$statusIndex)};
  border-radius: 50%;
  color: ${props => props.$statusIndex === 0 ? "var(--blue)" : "inherit"};
  font-size: ${props => props.$statusIndex === 0 ? "2rem" : "inherit"};
`;

export default AppResult;
