export const dictionary = {
  en: {
    translation: {
      "height": "height",
      "Healthy weight": "Healthy weight",
      "imperial": "imperial",
      "metric": "metric",
      "Obesity (class I)": "Obesity (class I)",
      "Obesity (class II)": "Obesity (class II)",
      "Obesity (class III)": "Obesity (class III)",
      "Overweight": "Overweight",
      "Underweight": "Underweight",
      "weight": "weight",
    },
  },
  hu: {
    translation: {
      "height": "magasság",
      "Healthy weight": "Egészséges súlyú",
      "imperial": "angolszász",
      "metric": "metrikus",
      "Obesity (class I)": "Elhízott (I. osztály)",
      "Obesity (class II)": "Elhízott (II. osztály)",
      "Obesity (class III)": "Elhízott (III. osztály)",
      "Overweight": "Túlsúlyos",
      "Underweight": "Sovány",
      "weight": "súly",
    },
  },
};
