import styled from "styled-components";

const AppCalculator = styled.main`
  display: grid;
  justify-content: center;
`;

  export const AppCalculatorBox = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  position: relative;
  margin-bottom: 2rem;
  padding: 0 6rem 2rem;
  width: min(80dvw, 480px);
  overflow: clip;
  background-color: var(--blue);
  border-radius: 4rem;
`

export default AppCalculator;
