import { useEffect, useState } from "react";
import AppResult, { AppResultNumber, AppResultText } from "./style";
import getBmiResult from "../../utils";
import type { calculatorPropsType } from "../../data-structures"
import { useTranslation } from "react-i18next";

const Result = (props: calculatorPropsType) => {
  const { t } = useTranslation();
  const { height, measSys, weight } = props;
  const statusText = [
    "",
    t("Underweight"),
    t("Healthy weight"),
    t("Overweight"),
    t("Obesity (class I)"),
    t("Obesity (class II)"),
    t("Obesity (class III)")
  ];
  const [statusIndex, setStatusIndex] = useState(0);
  const result = getBmiResult(height, measSys, weight);

  useEffect(() => {
    getStatus(result);
  }, [result]);

  const getStatus = (result: number) => {
    switch (true) {
      case result === 0: {
        setStatusIndex(0);
        break;
      }
      case result < 18.5: {
        setStatusIndex(1);
        break;
      }
      case result < 25: {
        setStatusIndex(2);
        break;
      }
      case result < 30: {
        setStatusIndex(3);
        break;
      }
      case result < 35: {
        setStatusIndex(4);
        break;
      }
      case result < 40: {
        setStatusIndex(5);
        break;
      }
      default: {
        setStatusIndex(6);
      }
    }
  };

  return (
    <AppResult>
      <AppResultText>
        {statusText[statusIndex]}
      </AppResultText>
      <AppResultNumber $statusIndex={statusIndex}>
        {result}
      </AppResultNumber>
    </AppResult>
  );
};

export default Result;
