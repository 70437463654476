import styled from "styled-components";

const AppInputField = styled.input`
  margin-bottom: 1.5rem;
  padding: 0.5rem 2.5rem;
  width: 100%;
  background-color: var(--white);
  border: 0;
  border-radius: 2rem;
  color: var(--blue);
  font-size: 1.25rem;
  font-weight: 600;
  text-align: center;
`;

export const AppInputFieldWrap = styled.div`
  position: relative;
`;

export const AppInputFieldUnit = styled.span`
  display: block;
  position: absolute;
  top: 0;
  right: 1.5rem;
  width: 1rem;
  line-height: 2.5rem;
  color: var(--blue);
  text-transform: uppercase;
`;

export default AppInputField;
