import AppMeasSysSelector from "./style";
import { InputRadio } from "..";
import { measSysSelectorProps } from "../../data-structures";
import { useTranslation } from 'react-i18next';

const MeasSysSelector = ({ onChange }: measSysSelectorProps) => {
  const { t } = useTranslation();

  return (
    <AppMeasSysSelector>
      <InputRadio
        checked={true}
        id="metric"
        label={t("metric")}
        name="measSys"
        onChange={onChange}
      />
      <InputRadio
        id="imperial"
        label={t("imperial")}
        name="measSys"
        onChange={onChange}
      />
    </AppMeasSysSelector>
  );
};

export default MeasSysSelector;
