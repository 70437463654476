import { createGlobalStyle } from "styled-components";
import reset from "styled-reset";

const GlobalStyle = createGlobalStyle`
  ${reset}

  :root {
    --black: hsl(200 90% 4%);
    --black-20: hsl(200 90% 4% / 20%);
    --blue: hsl(200 70% 25%);
    --blue-dark: hsl(200 70% 11%);
    --blue-light: hsl(200 90% 55%);
    --green: hsl(140 80% 40%);
    --orange: hsl(30 80% 45%);
    --red: hsl(360 80% 45%);
    --white: hsl(0 0% 100%);
    --white-2: hsl(0 0% 100% / 2%);
    --white-8: hsl(0 0% 100% / 8%);
    --yellow: hsl(50 80% 45%);
  }

  * {
    box-sizing: border-box;
    outline: none;
  }

  html, body {
    font-size: 20px;
  }

  body {
    display: grid;
    align-items: center;
    justify-content: center;
    height: 100dvh;
    overflow: hidden;
    background-color: var(--blue-dark);
    color: var(--white);
    font-family: "Smooch Sans", sans-serif;
    font-optical-sizing: auto;
    font-weight: 400;
    font-style: normal;
  }

  input {
    font-family: "Smooch Sans", sans-serif;
  }
`;

export default GlobalStyle;
