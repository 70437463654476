import i18next from "i18next";
import { initReactI18next } from "react-i18next";
import { dictionary } from "./dictionary";

export const supportedLngs = {
  en: "English",
  hu: "Magyar",
};

const resources = dictionary;

i18next
  .use(initReactI18next)
  .init({
    lng: 'en',
    supportedLngs: Object.keys(supportedLngs),
    debug: false,
    interpolation: {
      escapeValue: false,
    },
    resources,
  });
