import { Label } from "..";
import AppInputField, { AppInputFieldUnit, AppInputFieldWrap } from "./style";
import type { inputFieldPropsType } from "../../data-structures";

const InputField = ({ id, label, max, min, name, onChange, type = "text", unit, value }: inputFieldPropsType) => {
  return (
    <>
      {label &&
        <Label
          id={id}
          text={label}
        />
      }
      <AppInputFieldWrap>
        <AppInputField
          id={id}
          max={max}
          min={min}
          name={name}
          onChange={onChange}
          type={type}
          value={value}
        />
        <AppInputFieldUnit>{unit}</AppInputFieldUnit>
      </AppInputFieldWrap>
    </>
  );
};

export default InputField;
