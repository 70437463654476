import { Helmet, HelmetProvider } from "react-helmet-async";
import { Header, Calculator } from "./components";
import GlobalStyle from "./assets/style/globbal";
import "./i18n/i18n";

const App = () => {
  return (
    <HelmetProvider>
      <Helmet>
        <link rel="apple-touch-icon" sizes="180x180" href="/images/apple-touch-icon.png" />
        <link rel="icon" type="image/png" sizes="32x32" href="/images/favicon-32x32.png" />
        <link rel="icon" type="image/png" sizes="16x16" href="/images/favicon-16x16.png" />
        <link rel="manifest" href="/images/site.webmanifest" />
        <link rel="mask-icon" href="/images/safari-pinned-tab.svg" color="#030d11" />
        <meta name="msapplication-TileColor" content="#030d11" />
        <meta name="theme-color" content="#030d11" />
        <link rel="preconnect" href="https://fonts.googleapis.com" />
        <link rel="preconnect" href="https://fonts.gstatic.com" crossOrigin="anonymous" />
        <link href="https://fonts.googleapis.com/css2?family=Smooch+Sans:wght@100..900&display=swap" rel="stylesheet" />
      </Helmet>
      <GlobalStyle />
      <Header />
      <Calculator />
    </HelmetProvider>
  );
}

export default App;
