import styled from "styled-components";

const AppInputRadio = styled.input`

`;

export const AppInputRadioWrap = styled.div`
  position: relative;
  overflow: hidden;
`;

export default AppInputRadio;
