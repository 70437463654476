const getBmiResult = (height: number, unit: string, weight: number): number => {
  const heightValue = Number(height);
  const weightValue = Number(weight);
  const multiplicationNumber = unit === "imperial"
    ? 703 / 100 ** 2
    : 1;

  return heightValue !== 0
    ? Math.round(weightValue * multiplicationNumber / (heightValue / 100) ** 2 * 10) / 10
    : 0;
};

export default getBmiResult;
