import { useTranslation } from "react-i18next";
import { supportedLngs } from "../../i18n/i18n";
import AppLngSwitcher, { AppLngSwitcherItemWrap, AppLngSwitcherItem } from "./style";

const LanguageSwitcher = () => {
  const { i18n } = useTranslation();
  const activeLng = i18n.resolvedLanguage;

  return (
    <AppLngSwitcher>
      {Object.entries(supportedLngs).map(([code, name]) => (
        <AppLngSwitcherItemWrap key={code}>
          <AppLngSwitcherItem
            $active={activeLng === code}
            onClick={(e) => i18n.changeLanguage(code)}
            title={name}
          >
            {code}
          </AppLngSwitcherItem>
        </AppLngSwitcherItemWrap>
      ))}
    </AppLngSwitcher>
  );
};

export default LanguageSwitcher;
