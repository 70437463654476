import { Logo } from "..";
import AppHeader from "./style";

const Header = () => {
  return (
    <AppHeader>
      <Logo height="100px" />
    </AppHeader>
  )
};

export default Header;
