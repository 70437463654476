import styled from "styled-components";

const AppLogo = styled.svg`
  fill-rule: evenodd;
  clip-rule: evenodd;
  stroke-linecap: round;
  stroke-linejoin: bevel;
  stroke-miterlimit:2;
  height: ${props => props.height};
`;

export const AppLogoPath = styled.path`
  fill: var(--white);
  fill-rule: nonzero;
`;

export const AppLogoRect = styled.rect`
  fill: var(--white);
  fill-rule: nonzero;
`;

export default AppLogo;
