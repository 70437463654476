import AppLabel from "./style";
import type { labelPropsType } from "../../data-structures";

const Label = ({id, text}: labelPropsType) => {
  return (
    <AppLabel htmlFor={id}>{text}</AppLabel>
  );
};

export default Label;
